import { default as _91id_93e1mCNtUI0UMeta } from "/srv/node/app/pages/customers/[id].vue?macro=true";
import { default as indexv4IdqrZ8uFMeta } from "/srv/node/app/pages/customers/index.vue?macro=true";
import { default as indexspapEOacWuMeta } from "/srv/node/app/pages/index.vue?macro=true";
import { default as _91id_933gpvPbP24qMeta } from "/srv/node/app/pages/products/[dynamicurl]/[id].vue?macro=true";
import { default as indexyEaqo6m7pjMeta } from "/srv/node/app/pages/products/index.vue?macro=true";
import { default as _91id_93XwkqViji0qMeta } from "/srv/node/app/pages/projects/[id].vue?macro=true";
import { default as indexVnlAaZoOKsMeta } from "/srv/node/app/pages/projects/index.vue?macro=true";
import { default as _91id_93Hee5QPbmr0Meta } from "/srv/node/app/pages/rentals/[id].vue?macro=true";
import { default as indexIMad8QTqWEMeta } from "/srv/node/app/pages/rentals/index.vue?macro=true";
import { default as _91id_93bfOiumylYKMeta } from "/srv/node/app/pages/uns/[id].vue?macro=true";
import { default as indexKZTemiyjBzMeta } from "/srv/node/app/pages/uns/index.vue?macro=true";
import { default as _91id_933FKau1EJMUMeta } from "/srv/node/app/pages/uns/mqtt/[id].vue?macro=true";
import { default as indexzBaW4stVaOMeta } from "/srv/node/app/pages/uns/mqtt/index.vue?macro=true";
export default [
  {
    name: _91id_93e1mCNtUI0UMeta?.name ?? "customers-id",
    path: _91id_93e1mCNtUI0UMeta?.path ?? "/customers/:id()",
    meta: _91id_93e1mCNtUI0UMeta || {},
    alias: _91id_93e1mCNtUI0UMeta?.alias || [],
    redirect: _91id_93e1mCNtUI0UMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexv4IdqrZ8uFMeta?.name ?? "customers",
    path: indexv4IdqrZ8uFMeta?.path ?? "/customers",
    meta: indexv4IdqrZ8uFMeta || {},
    alias: indexv4IdqrZ8uFMeta?.alias || [],
    redirect: indexv4IdqrZ8uFMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexspapEOacWuMeta?.name ?? "index",
    path: indexspapEOacWuMeta?.path ?? "/",
    meta: indexspapEOacWuMeta || {},
    alias: indexspapEOacWuMeta?.alias || [],
    redirect: indexspapEOacWuMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933gpvPbP24qMeta?.name ?? "products-dynamicurl-id",
    path: _91id_933gpvPbP24qMeta?.path ?? "/products/:dynamicurl()/:id()",
    meta: _91id_933gpvPbP24qMeta || {},
    alias: _91id_933gpvPbP24qMeta?.alias || [],
    redirect: _91id_933gpvPbP24qMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/products/[dynamicurl]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyEaqo6m7pjMeta?.name ?? "products",
    path: indexyEaqo6m7pjMeta?.path ?? "/products",
    meta: indexyEaqo6m7pjMeta || {},
    alias: indexyEaqo6m7pjMeta?.alias || [],
    redirect: indexyEaqo6m7pjMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XwkqViji0qMeta?.name ?? "projects-id",
    path: _91id_93XwkqViji0qMeta?.path ?? "/projects/:id()",
    meta: _91id_93XwkqViji0qMeta || {},
    alias: _91id_93XwkqViji0qMeta?.alias || [],
    redirect: _91id_93XwkqViji0qMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVnlAaZoOKsMeta?.name ?? "projects",
    path: indexVnlAaZoOKsMeta?.path ?? "/projects",
    meta: indexVnlAaZoOKsMeta || {},
    alias: indexVnlAaZoOKsMeta?.alias || [],
    redirect: indexVnlAaZoOKsMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Hee5QPbmr0Meta?.name ?? "rentals-id",
    path: _91id_93Hee5QPbmr0Meta?.path ?? "/rentals/:id()",
    meta: _91id_93Hee5QPbmr0Meta || {},
    alias: _91id_93Hee5QPbmr0Meta?.alias || [],
    redirect: _91id_93Hee5QPbmr0Meta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/rentals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexIMad8QTqWEMeta?.name ?? "rentals",
    path: indexIMad8QTqWEMeta?.path ?? "/rentals",
    meta: indexIMad8QTqWEMeta || {},
    alias: indexIMad8QTqWEMeta?.alias || [],
    redirect: indexIMad8QTqWEMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/rentals/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bfOiumylYKMeta?.name ?? "uns-id",
    path: _91id_93bfOiumylYKMeta?.path ?? "/uns/:id()",
    meta: _91id_93bfOiumylYKMeta || {},
    alias: _91id_93bfOiumylYKMeta?.alias || [],
    redirect: _91id_93bfOiumylYKMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/uns/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKZTemiyjBzMeta?.name ?? "uns",
    path: indexKZTemiyjBzMeta?.path ?? "/uns",
    meta: indexKZTemiyjBzMeta || {},
    alias: indexKZTemiyjBzMeta?.alias || [],
    redirect: indexKZTemiyjBzMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/uns/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933FKau1EJMUMeta?.name ?? "uns-mqtt-id",
    path: _91id_933FKau1EJMUMeta?.path ?? "/uns/mqtt/:id()",
    meta: _91id_933FKau1EJMUMeta || {},
    alias: _91id_933FKau1EJMUMeta?.alias || [],
    redirect: _91id_933FKau1EJMUMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/uns/mqtt/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzBaW4stVaOMeta?.name ?? "uns-mqtt",
    path: indexzBaW4stVaOMeta?.path ?? "/uns/mqtt",
    meta: indexzBaW4stVaOMeta || {},
    alias: indexzBaW4stVaOMeta?.alias || [],
    redirect: indexzBaW4stVaOMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/uns/mqtt/index.vue").then(m => m.default || m)
  }
]