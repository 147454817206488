export default defineNuxtPlugin((nuxtApp) => {
    // Doing something with nuxtApp
    return {
        provide: {
            sayHello: (msg:string) => console.log( `hello ${msg}`),
            apiGet: (endpoint:string, ) => apiGet( endpoint ),
            apiPost: (endpoint:string, body:string, content:string) => apiPost( endpoint, body, content),
            apiPut: (endpoint:string, body:string,) => apiPut( endpoint, body),
            apiDelete: (endpoint:string, ) => apiDelete( endpoint ),
        }
    }
});

/*
  * GET FUNCTION PLUGIN API
  * @param endpoint 
  * @returns 
  */
function apiGet(endpoint){
  const { status, data:token } = useSession();
  const config = useRuntimeConfig()
  return $fetch( `/api/v1/${endpoint}`, {
      method: 'GET',
      baseURL: config.public.API_BASE_URL,
      headers: {
        Authorization: `Bearer ${token?.value?.accessToken}`,
        "Content-Type": 'application/json'
      }
  })
}


/*
  * GET FUNCTION PLUGIN API
  * @param endpoint 
  * @returns 
  */
function apiPut(endpoint, body){
  const { status, data:token } = useSession();
  const config = useRuntimeConfig()
  return $fetch( `/api/v1/${endpoint}`, {
      method: 'PUT',
      baseURL: config.public.API_BASE_URL,
      headers: {
        Authorization: `Bearer ${token?.value?.accessToken}`,
        "Content-Type": 'application/json'
      },
      body: body
  })
}

/*
  * GET FUNCTION PLUGIN API
  * @param endpoint 
  * @returns 
  */
function apiPost(endpoint, body, content_type='application/json'){
  const { status, data:token } = useSession();
  const config = useRuntimeConfig()
  return $fetch( `/api/v1/${endpoint}`, {
      method: 'POST',
      baseURL: config.public.API_BASE_URL,
      headers: {
        Authorization: `Bearer ${token?.value?.accessToken}`,
        // "Content-Type": content_type
      },
      body: body
  })
}


/*
  * GET FUNCTION PLUGIN API
  * @param endpoint 
  * @returns 
  */
function apiDelete(endpoint){
  const { status, data:token } = useSession();
  const config = useRuntimeConfig()
  return $fetch( `/api/v1/${endpoint}`, {
      method: 'DELETE',
      baseURL: config.public.API_BASE_URL,
      headers: {
        Authorization: `Bearer ${token?.value?.accessToken}`,
        "Content-Type": 'application/json'
      }
  })
}
